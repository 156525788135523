import React from 'react';
import ReactDOM from 'react-dom';
import { Header as SalesHeader, Footer as SalesFooter } from '@ux/sales-header';
import ProductNav from '@ux/product-nav';
import ResellerSalesFooter from '@ux/reseller-sales-footer';

function Header(props) {
  return <SalesHeader ProductNav={ ProductNav } { ...props } />
}

function Footer(props) {
  return <SalesFooter ResellerSalesFooter={ ResellerSalesFooter } { ...props } />
}

//
// Copy down {propTypes,defaultProps} for a proper
// pass through functional component.
//
Header.propTypes = SalesHeader.propTypes;
Header.defaultProps = SalesHeader.defaultProps;

Footer.propTypes = SalesFooter.propTypes;
Footer.defaultProps = SalesFooter.defaultProps;

export {
  Header,
  Footer
};
